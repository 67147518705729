import React, { DetailedHTMLProps, forwardRef, HTMLAttributes } from 'react';
import { Block, View } from 'vcc-ui';
import { useAccordion } from '../Accordion/AccordionContext';
import { useAccordionController } from '../AccordionController';
import { Expand } from '../utils/Expand';
import { SharedProps } from '../utils/shared.props';
import { useSummaryId } from '../utils/useSummaryId';

interface Props
  extends Omit<SharedProps, 'id'>,
    DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {}

export const AccordionDetails = forwardRef<
  HTMLDivElement,
  React.ComponentPropsWithoutRef<React.FC<Props>>
>(function AccordionDetails({ children, extend, ...rest }, ref) {
  const { isExpanded, lazyRenderDetails, onAnimationEnd } =
    useAccordion() || {};
  const summaryId = useSummaryId();
  const { reducedMotion } = useAccordionController() || {};
  const id = summaryId ? `${summaryId}-content` : undefined;
  const labelledBy = summaryId ? `${summaryId}-header` : undefined;
  return (
    <View
      {...rest}
      extend={[{ border: '1px solid transparent' }, extend]}
      ref={ref}
      as="div"
      id={id}
      aria-labelledby={labelledBy}
      role="region"
    >
      <Expand
        isExpanded={!!isExpanded}
        unmountOnExit={lazyRenderDetails}
        onAnimationEnd={onAnimationEnd}
        animateOpacity
        reducedMotion={reducedMotion}
        extend={({ theme: { baselineGrid } }) => ({
          paddingBottom: 2 * baselineGrid,
        })}
      >
        {children}
      </Expand>
      <Block
        className={`${summaryId}-react-accordion__divider`}
        extend={({ theme: { color } }) => ({
          height: 1,
          background: color.ornament.divider,
          display: 'none',
        })}
      />
    </View>
  );
});
