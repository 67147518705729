import { useEffect, useState } from 'react';
import { useAccordion } from '../Accordion/AccordionContext';

export const useSummaryId = (): string => {
  const { accordionKey } = useAccordion() || {};
  // only generate after mount to prevent SSR mismatches
  const [summaryId, setSummaryId] = useState('');
  useEffect(() => {
    if (accordionKey) {
      setSummaryId(accordionKey);
    }
  }, [accordionKey]);
  return summaryId;
};
