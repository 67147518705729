import React, { useRef } from 'react';
import FocusTrap from 'focus-trap-react';
import { Block, Col, Grid, Row, useTheme } from 'vcc-ui';
import { useAppear } from '../Appear';
import { CloseBar } from '../CloseBar';
import { useOverlay } from '../hooks';
import { useOverlayStacker } from '../OverlayStacker';
import { PreventClickPassThrough } from '../PreventClickPassThrough';
import { contentWrap, forceFillAvailableSpace } from '../shared/style';
import { useContainerElements } from '../shared/useContainerElements';

export interface OverlayProps {
  children: React.ReactNode;

  /**
   * Callback function to be invoked on interaction with
   * the pane close icon
   */
  close: () => void;

  /**
   * If specified, modal will display constrain to the specific grid column width.
   * If empty, modal will render fullscreen
   */
  colSpan?: number;

  /**
   * Optionally specifiy a unique ID for the component.
   */
  'aria-labelledby'?: string;

  /**
   * Custom zIndex for use in cases of conflict with other absolutely positioned elements
   */
  zIndex?: number | string;

  /**
   * Describe the Overlays's response to interaction
   * @remarks Required, but typed optional as translated strings return undefined on first render
   */
  'aria-label'?: string;

  /**
   * Overlay will collapse to content height and will be center aligned vertically in the viewport
   */
  collapse?: boolean;
}

export const Overlay = ({
  children,
  close,
  colSpan,
  zIndex = 'calc(var(--sitenav-z-index-max, 1010) + 1)',
  collapse,
  ...rest
}: OverlayProps) => {
  const scrollingElRef = useRef<HTMLDivElement>(null);
  const { isOpen } = useAppear({
    scrollingElRef,
  });
  const { elementsMounted, closeAndTrackEvent } = useOverlay({
    close,
    isOpen,
  });
  const containerRef = useRef(null);

  const { color } = useTheme();
  const { stackedZIndex } = useOverlayStacker(zIndex);

  const containerElements = useContainerElements(containerRef);
  const hasMouseDown = useRef(false);

  return (
    <FocusTrap
      active={!!(isOpen && elementsMounted)}
      containerElements={containerElements}
    >
      <Block
        role="dialog"
        aria-modal="true"
        ref={containerRef}
        {...rest}
        extend={{
          backgroundColor: colSpan
            ? 'rgb(20 20 20 / 33%)'
            : color.background.primary,
          overflowY: 'hidden',
          ...forceFillAvailableSpace,
          zIndex: stackedZIndex,
          height: '100%',
          display: 'flex',
        }}
        onMouseDown={() => {
          hasMouseDown.current = true;
        }}
        onClick={() => {
          if (!colSpan || !hasMouseDown.current) return;
          closeAndTrackEvent();
          hasMouseDown.current = false;
        }}
      >
        <Block
          extend={{ alignSelf: collapse ? 'center' : 'inherit', width: '100%' }}
        >
          <FullScreenOrConstrained colSpan={colSpan} collapse={collapse}>
            <PreventClickPassThrough>
              <CloseBar closeAction={closeAndTrackEvent} />
              <div
                ref={scrollingElRef}
                style={{
                  overflowY: 'auto',
                  height: colSpan ? (collapse ? 'auto' : '100vh') : '100vh',
                  maxHeight: 'calc(100vh - 144px)',
                  backgroundColor: color.background.primary,
                  scrollbarGutter: 'stable',
                }}
              >
                <Block extend={contentWrap}>{children}</Block>
              </div>
            </PreventClickPassThrough>
          </FullScreenOrConstrained>
        </Block>
      </Block>
    </FocusTrap>
  );
};

const GridConstraint = ({
  children,
  colSpan,
  collapse,
}: {
  children: React.ReactNode;
  colSpan: number;
  collapse?: boolean;
}) => {
  return (
    <Grid>
      <Row align="center">
        <Col size={colSpan}>
          <Block
            extend={{
              marginTop: !collapse ? '44px' : '0',
            }}
          >
            {children}
          </Block>
        </Col>
      </Row>
    </Grid>
  );
};

const FullScreenOrConstrained = ({
  colSpan,
  children,
  collapse,
}: {
  colSpan?: number;
  collapse?: boolean;
  children: React.ReactNode;
}) =>
  !colSpan ? (
    <>{children}</>
  ) : (
    <GridConstraint collapse={collapse} colSpan={colSpan}>
      {children}
    </GridConstraint>
  );
