import { ExtendCSS } from 'vcc-ui';

export const iconAside = {
  minWidth: '24px',
  flexGrow: 0,
  justifyContent: 'center',
};

export const title = {
  flexGrow: 1,
  justifyContent: 'center',
};

export const titleBarStyle =
  (fixed: boolean): ExtendCSS =>
  ({ theme: { color, baselineGrid } }) => ({
    backgroundColor: color.background.primary,
    boxSizing: 'border-box',
    width: '100%',
    position: fixed ? 'sticky' : 'static',
    top: fixed ? '0' : '',
    height: '64px',
    paddingLeft: 3 * baselineGrid,
    paddingRight: 3 * baselineGrid,
    borderBottom: `1px solid ${color.ornament.divider}`,
    textAlign: 'center',
    transition: 'height 300ms ease-in-out 0s',
    justifyContent: 'center',
  });

export const titleTextTransition = {
  margin: 0,
  transition: 'all 300ms ease-in-out',
  fontWeight: 500,
};
