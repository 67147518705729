import React from 'react';
import { Click, useTheme } from 'vcc-ui';
import { InfoBanner, InfoBannerProps } from '../components/InfoBanner';
import { InfoCTA } from '../components/InfoCTA';

interface CTAProps {
  linkText: string;
}

type ClickOrHref =
  | { href?: never; onClick: (e: React.MouseEvent<HTMLElement>) => void }
  | { href: string; onClick?: never };

type InfoBannerWithCTAProps = InfoBannerProps & CTAProps & ClickOrHref;

export const InfoBannerWithCTA: React.FC<InfoBannerWithCTAProps> = (props) => {
  const { children, linkText, href, onClick, ...rest } = props;
  const { typeScale, direction } = useTheme();
  const { kelly } = typeScale;

  return (
    <InfoBanner {...rest}>
      {href || onClick ? (
        <Click
          dir={direction}
          href={href}
          onClick={
            onClick && ((e: React.MouseEvent<HTMLElement>) => onClick(e))
          }
          extend={({ theme }) => ({
            color: theme.color.foreground.primary,
            textDecoration: 'none',
            ...kelly.emphasis.styles,
          })}
        >
          {children}
          <InfoCTA mobileCollapse>{linkText}</InfoCTA>
        </Click>
      ) : (
        <>
          {children}
          <InfoCTA mobileCollapse>{linkText}</InfoCTA>
        </>
      )}
    </InfoBanner>
  );
};
