import React, { useEffect } from 'react';

const useResetScroll = ({
  isOpen,
  scrollingElRef,
  resetScroll = true,
}: {
  isOpen: boolean;
  scrollingElRef: React.RefObject<HTMLDivElement>;
  resetScroll?: boolean;
}) => {
  useEffect(() => {
    if (resetScroll && isOpen) {
      scrollingElRef.current?.scrollTo?.(0, 0);
    }
  }, [isOpen, resetScroll, scrollingElRef]);
  return scrollingElRef;
};

export default useResetScroll;
