import React, { useEffect, useId, useRef, useState } from 'react';
import {
  useElementDimensions,
  useResizeObserver,
} from '@volvo-cars/react-layout-utils';
import { useElementHasScrolled } from '@volvo-cars/react-scroll-utils';
import FocusTrap from 'focus-trap-react';
import { Block, Col, Grid, Row, useTheme } from 'vcc-ui';
import { useAppear } from '../Appear';
import { useOverlay } from '../hooks';
import { useOverlayStacker } from '../OverlayStacker';
import { PreventClickPassThrough } from '../PreventClickPassThrough';
import { contentWrap, forceFillAvailableSpace } from '../shared/style';
import { useContainerElements } from '../shared/useContainerElements';
import { TitleBar } from '../TitleBar';

export interface TitledOverlayProps {
  children: React.ReactNode;

  /**
   * Callback function to be invoked on interaction with
   * the pane close icon
   */
  close: () => void;

  /**
   * If specified, modal will display constrain to the specific grid column width.
   * If empty, modal will render fullscreen
   */
  colSpan?: number;

  /**
   * Optionally specifiy a unique ID for the component.
   * One will be generated for you if left blank
   **/
  elementId?: string;

  /**
   * If true, title bar mounts tall and minimises on scroll.
   * Otherwise, fixed at standard height
   */
  minimisableTitle?: boolean;

  /**
   * If a callback function is passed,
   * displays a back navigation icon button in the title bar
   */
  navPreviousPane?: () => void;

  /**
   * Pane title
   */
  title: string;

  /**
   * Custom zIndex for use in cases of conflict with other absolutely positioned elements
   */
  zIndex?: number | string;

  /**
   * Collapses the height to fit the content. Centered aligned.
   */
  collapse?: boolean;
}

const DEFAULT_MARGIN = 44;
const MOBILE_MARGIN = 16;

export const TitledOverlay = ({
  children,
  close,
  colSpan,
  elementId,
  minimisableTitle,
  navPreviousPane,
  title,
  zIndex = 'calc(var(--sitenav-z-index-max, 1010) + 1)',
  collapse,
  ...rest
}: TitledOverlayProps) => {
  const [scrollingElRef, hasScrolled] = useElementHasScrolled({});
  const [containerHeight, setContainerHeight] = useState(0);
  const generatedId = useId();
  const id = elementId || generatedId;
  const { ref: titleRef, height: titleBarHeight } =
    useResizeObserver<HTMLDivElement>();
  const [containerRef, containerDimensions] = useElementDimensions();

  const { isOpen } = useAppear({
    scrollingElRef,
  });
  const { elementsMounted, closeAndTrackEvent } = useOverlay({
    close,
    isOpen,
  });
  const { stackedZIndex } = useOverlayStacker(zIndex);

  const IS_CONSTRAINED_MODAL = colSpan;
  const computedMargin = useRef(DEFAULT_MARGIN);

  useEffect(() => {
    titleBarHeight &&
      setContainerHeight(() => {
        const height = window.innerHeight - titleBarHeight;

        computedMargin.current =
          containerDimensions.width >= 480 ? DEFAULT_MARGIN : MOBILE_MARGIN;

        if (IS_CONSTRAINED_MODAL) {
          return height - computedMargin.current * 2;
        }

        return height;
      });
  }, [hasScrolled, titleBarHeight, IS_CONSTRAINED_MODAL, containerDimensions]);

  const { color } = useTheme();

  const containerElements = useContainerElements(containerRef);
  const hasMouseDown = useRef(false);

  return (
    <FocusTrap
      active={!!(isOpen && elementsMounted)}
      containerElements={containerElements}
    >
      <Block
        ref={containerRef}
        role="dialog"
        aria-modal="true"
        aria-labelledby={id}
        {...rest}
        extend={{
          backgroundColor: colSpan
            ? 'rgb(20 20 20 / 33%)'
            : color.background.primary,
          overflowY: 'hidden',
          ...forceFillAvailableSpace,
          zIndex: stackedZIndex,
          height: '100%',
          display: 'flex',
        }}
        onMouseDown={() => {
          hasMouseDown.current = true;
        }}
        onClick={() => {
          if (!colSpan || !hasMouseDown.current) return;
          closeAndTrackEvent();
          hasMouseDown.current = false;
        }}
      >
        <Block
          extend={{
            alignSelf: collapse && colSpan ? 'center' : 'inherit',
            width: '100%',
          }}
        >
          <FullScreenOrConstrained
            colSpan={colSpan}
            responsiveMargin={computedMargin.current}
          >
            <PreventClickPassThrough>
              <TitleBar
                ref={titleRef}
                id={id}
                closeAction={closeAndTrackEvent}
                fixed
                minimised={minimisableTitle ? hasScrolled : true}
                showBackArrow={!!navPreviousPane}
                backArrowAction={navPreviousPane}
              >
                {title}
              </TitleBar>
              <div
                style={{
                  height: colSpan
                    ? collapse
                      ? 'auto'
                      : containerHeight
                    : containerHeight,
                  maxHeight: colSpan ? 'calc(100vh - 144px)' : 'auto',
                  overflowY: 'auto',
                  backgroundColor: color.background.primary,
                }}
                ref={scrollingElRef}
              >
                <Block extend={contentWrap}>{children}</Block>
              </div>
            </PreventClickPassThrough>
          </FullScreenOrConstrained>
        </Block>
      </Block>
    </FocusTrap>
  );
};

const FullScreenOrConstrained = ({
  colSpan,
  children,
  responsiveMargin,
}: {
  colSpan?: number;
  children: React.ReactNode;
  responsiveMargin: number;
  collapse?: boolean;
}) =>
  !colSpan ? (
    <>{children}</>
  ) : (
    <GridConstraint colSpan={colSpan} responsiveMargin={responsiveMargin}>
      {children}
    </GridConstraint>
  );

const GridConstraint = ({
  children,
  colSpan,
  responsiveMargin,
}: {
  children: React.ReactNode;
  colSpan: number;
  responsiveMargin: number;
  collapse?: boolean;
}) => {
  return (
    <Grid>
      <Row align="center">
        <Col size={colSpan}>
          <Block
            extend={{
              marginTop: responsiveMargin,
              marginBottom: responsiveMargin,
              height: 'auto',
            }}
          >
            {children}
          </Block>
        </Col>
      </Row>
    </Grid>
  );
};
