import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useLayoutEffect } from '@volvo-cars/react-layout-utils';
import { getScrollBarWidth } from '../getScrollBarWidth';

let sheet: CSSStyleSheet | undefined;
function getSheet() {
  if (!sheet && document && document.head) {
    const styleEl = document.createElement('style');
    document.head.appendChild(styleEl);
    sheet = styleEl.sheet as CSSStyleSheet;
  }
  return sheet;
}

const SetScrollLockContext = createContext<(locked: boolean) => void>(() => {});
const IsScrollLockedContext = createContext<boolean>(false);

/**
 * Provides the context for the scroll lock hooks.
 */
export const ScrollLockProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [isScrollLocked, setScrollLock] = useState(false);
  return (
    <SetScrollLockContext.Provider value={setScrollLock}>
      <IsScrollLockedContext.Provider value={isScrollLocked}>
        {children}
      </IsScrollLockedContext.Provider>
    </SetScrollLockContext.Provider>
  );
};

/**
 * Return browser's scroll bar width only if document scroll bar is visible
 * and scroll is locked, otherwise return 0.
 */
export function useScrollBarOffset() {
  let hasDocumentScrollbar;
  if (typeof document !== 'undefined') {
    const root = document.documentElement;
    hasDocumentScrollbar = root.scrollHeight > root.clientHeight;
  }

  const isScrollLocked = useContext(IsScrollLockedContext);

  const scrollBarWidth = useScrollBarWidth();

  // return scroll bar width only when document has scroll bar and scroll lock is enabled
  if (hasDocumentScrollbar && isScrollLocked) {
    return scrollBarWidth;
  }

  return 0;
}

/**
 * Get the width of the browser scroll bar.
 */
export function useScrollBarWidth() {
  const [scrollBarWidth, setScrollBarWidth] = useState(getScrollBarWidth());
  useEffect(() => {
    if (scrollBarWidth > 0) return;

    const update = () => setScrollBarWidth(getScrollBarWidth());
    if (typeof ResizeObserver === 'function') {
      const observer = new ResizeObserver(update);
      observer.observe(document.body);
      return () => observer.disconnect();
    } else {
      const timeout = setTimeout(update, 500);
      return () => clearTimeout(timeout);
    }
  }, [scrollBarWidth]);
  return scrollBarWidth;
}

/**
 * Enable or disable scroll lock.
 */
export function useScrollLock(enableScrollLock: boolean) {
  const setScrollLock = useContext(SetScrollLockContext);

  const getScrollLockStyles = useCallback(
    () =>
      `body {
      overflow: hidden !important;
    }`,
    []
  );

  useLayoutEffect(() => {
    if (!enableScrollLock) return;
    setScrollLock(true);
    const styles = getScrollLockStyles();
    const sheet = getSheet();
    sheet?.insertRule(styles, 0);
    return () => {
      sheet?.deleteRule(0);
      setScrollLock(false);
    };
  }, [setScrollLock, enableScrollLock, getScrollLockStyles]);
}
