import { ExtendCSS } from 'vcc-ui';

export const contentWrap: ExtendCSS = {
  height: '100%',
  flexDirection: 'row',
  justifyContent: 'center',
  width: '100%',
  marginLeft: 'auto',
  marginRight: 'auto',
  // Padding percentage is always relative to parent width
  // Which is why we can use 100% here to reference parent
  // width when the variable is used to set paddings

  // This variable gives 1px if parent width is larger than 480px and 0px if less than
  // 480px with 0.1px uncertainty
  '--parent-width-larger-than-480-px':
    'calc(10 * clamp(0px, calc(100% - 480px), 0.1px))',
};

export const forceFillAvailableSpace: ExtendCSS = {
  position: 'fixed',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
};
