import React from 'react';
import { useTranslate } from '@volvo-cars/react-shared-translations';
import { Flex, IconButton } from 'vcc-ui';
import { closeBarStyle } from './CloseBar.styles';

export interface CloseBarProps {
  /** The function to be invoked when closing the modal */
  closeAction: () => void;
}

export const CloseBar = ({ closeAction }: CloseBarProps) => {
  const closeLabel = useTranslate('react-overlay.close');

  return (
    <Flex extend={closeBarStyle}>
      <IconButton
        iconName="navigation-close"
        aria-label={closeLabel}
        onClick={closeAction}
      />
    </Flex>
  );
};
