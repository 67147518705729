import React, { forwardRef } from 'react';
import { Flex } from 'vcc-ui';
import {
  AccordionSummaryBase,
  AccordionSummaryBaseProps,
  Chevron,
} from './AccordionSummaryBase';

export const AccordionSummary = forwardRef<
  HTMLDivElement,
  React.ComponentPropsWithoutRef<
    React.FC<AccordionSummaryBaseProps & { children?: React.ReactNode }>
  >
>(function AccordionSummary({ children, ...props }, ref) {
  return (
    <AccordionSummaryBase ref={ref} {...props}>
      {({ isExpanded, extend, iconAlignment }) => (
        <Flex
          extend={[
            ({ theme: { baselineGrid } }) => ({
              flexDirection: 'row',
              padding: `${2 * baselineGrid}px 0px`,
            }),
            extend,
          ]}
        >
          <Flex extend={{ flexGrow: 1 }}>{children}</Flex>
          <Flex
            extend={{
              alignSelf: iconAlignment === 'center' ? 'center' : 'auto',
              flexShrink: 0,
            }}
          >
            <Chevron direction={isExpanded ? 'up' : 'down'} />
          </Flex>
        </Flex>
      )}
    </AccordionSummaryBase>
  );
});
