import React, { DetailedHTMLProps, forwardRef, HTMLAttributes } from 'react';
import { Block } from 'vcc-ui';
import { SharedProps } from './shared.props';

export const ButtonDiv = forwardRef<
  HTMLDivElement,
  React.ComponentPropsWithoutRef<
    React.FC<
      DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> &
        SharedProps & { disabled?: boolean }
    >
  >
>(function ButtonDiv(
  { children, onClick, disabled, onKeyPress, ...rest },
  ref
) {
  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    onKeyPress?.(event);
    if (disabled) return;
    // Trigger click on Spacebar or Enter
    if (
      (event.key === 'Enter' ||
        event.key === ' ' ||
        event.key === 'Spacebar') &&
      !disabled
    ) {
      event.preventDefault();
      //@ts-expect-error
      onClick?.(event);
    }
  };
  return (
    <Block
      role="button"
      {...rest}
      ref={ref}
      as="div"
      onKeyPress={handleKeyPress}
      onClick={onClick}
      tabIndex={disabled ? -1 : 0}
    >
      {children}
    </Block>
  );
});
