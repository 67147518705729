import { useRef, useState } from 'react';
import { useLayoutEffect } from '@volvo-cars/react-layout-utils';
import { usePrevious } from '@volvo-cars/react-utils';

interface useElementHasScrolledArgs {
  /** Whether hook should report scroll status */
  disabled?: boolean;

  /** When set, won't return true until scroll has passed the specified threshold in pixels */
  offsetThreshold?: number;
}

/**
 * Creates and returns a React Ref for binding to the desired scroll element
 * Returns true when an element has been scrolled, otherwise false.
 */

export function useElementHasScrolled<T extends HTMLElement = HTMLDivElement>({
  disabled = false,
  offsetThreshold = 0,
}: useElementHasScrolledArgs = {}) {
  const scrollElementRef = useRef<T>(null);
  const cachedScrollElementRef = scrollElementRef;
  const currentScrollElement = cachedScrollElementRef.current;

  const [hasScrolledDown, setHasScrolledDown] = useState<boolean>(false);
  const prevScrollElement = usePrevious(currentScrollElement);
  const scrollTicking = useRef(false);

  useLayoutEffect(() => {
    const updateScrollDirection = () => {
      scrollTicking.current = false;

      if (!currentScrollElement) {
        return;
      }

      const scrollY = currentScrollElement.scrollTop ?? 0;

      if (scrollY === 0) {
        setHasScrolledDown(false);
        return;
      }

      if (scrollY < offsetThreshold) {
        setHasScrolledDown(false);
        return;
      }

      setHasScrolledDown(true);
    };

    const handleScroll = () => {
      if (!scrollTicking.current) {
        scrollTicking.current = true;
        window.requestAnimationFrame(updateScrollDirection);
      }
    };

    if (disabled) {
      return setHasScrolledDown(false);
    } else {
      window.requestAnimationFrame(updateScrollDirection);
    }

    if (currentScrollElement) {
      prevScrollElement &&
        prevScrollElement.removeEventListener('scroll', handleScroll);

      currentScrollElement.removeEventListener('scroll', handleScroll);
      currentScrollElement.addEventListener('scroll', handleScroll);
    }

    return () => {
      currentScrollElement?.removeEventListener('scroll', handleScroll);
    };
  }, [disabled, offsetThreshold, prevScrollElement, currentScrollElement]);

  return [cachedScrollElementRef, hasScrolledDown] as const;
}
