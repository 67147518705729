import { useCallback, useEffect, useRef, useState } from 'react';

const COOKIE_NAME = 'info-banner-dismissed';
const defaultKey = '_bannerdefault_';

/**
 * Controls the dismissal and visibility state of an InfoBanner.
 *
 * @param persistToCookie - Persist the dismissal state across page loads.
 * @param key - With `key` the dismissal will be valid on all pages,
 * otherwise only for the current path. Use a unique key, prefixed with your application name.
 */
export function useDismiss({
  key = defaultKey,
  persistToCookie = false,
}: {
  key?: string;
  persistToCookie?: boolean | (() => boolean);
} = {}) {
  // Dismissals not tracked by cookies will always be initially visible
  // Others might have been dismissed already, so hide until we know
  const [visible, setVisible] = useState(!persistToCookie);
  const didCheckCookie = useRef('');

  useEffect(() => {
    if (didCheckCookie.current !== key && getValue(persistToCookie)) {
      setVisible(!isDismissed(key));
      didCheckCookie.current = key;
    }
  }, [key, persistToCookie]);

  const dismiss = useCallback(() => {
    setVisible(false);
    if (getValue(persistToCookie)) {
      saveDismissal(key);
      didCheckCookie.current = key;
    }
  }, [key, persistToCookie]);

  return { dismiss, visible };
}

function saveDismissal(key: string) {
  const dismissals = getParamsCookie();
  dismissals.set(key, 'true');
  let cookie = `${COOKIE_NAME}=${dismissals};domain=${window.location.hostname}`;
  if (key !== defaultKey) {
    // Set cookie globally for domain. document.cookie defaults to current path.
    cookie += `${cookie};path=/`;
  }
  document.cookie = cookie;
}

function isDismissed(key: string) {
  const dismissals = getParamsCookie();
  return dismissals.get(key) === 'true';
}

function getParamsCookie() {
  return new URLSearchParams(
    document.cookie.match(`(^|;)\\s*${COOKIE_NAME}\\s*=\\s*([^;]+)`)?.pop()
  );
}

function getValue(x?: boolean | (() => boolean)) {
  if (typeof x === 'boolean') {
    return x;
  }
  if (typeof x === 'function') {
    return x();
  }
}
