import React from 'react';
import { Dimensions } from '@volvo-cars/react-layout-utils';
import { Block, ExtendCSS } from 'vcc-ui';

export interface ContentBlockProps {
  /** Content block will accept any valid JSX element */
  children: React.ReactNode;

  /** Element padding is conditional based on parent container size
   * @deprecated
   * This does nothing anymore, parent width is now calculated internally with
   * css
   */
  parentDimensions?: Dimensions;
}

const ContentBlockCss: ExtendCSS = ({ theme: { baselineGrid, color } }) => ({
  paddingTop: `calc(${5 * baselineGrid}px + calc(${
    3 * baselineGrid
  } * var(--parent-width-larger-than-480-px, 0px)))`,
  paddingRight: 3 * baselineGrid,
  paddingBottom: `calc(${5 * baselineGrid}px + calc(${
    3 * baselineGrid
  } * var(--parent-width-larger-than-480-px, 0px)))`,
  paddingLeft: 3 * baselineGrid,
  backgroundColor: color.background.primary,
});

// Not needed anymore and should be removed in next major version
export const ContentBlock = ({ children }: ContentBlockProps) => {
  return <Block extend={ContentBlockCss}>{children}</Block>;
};
