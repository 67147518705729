import React, { useMemo, useState } from 'react';
import { ExtendCSS, View } from 'vcc-ui';
import { ExpandProps } from '../utils/Expand';
import { getRandomId } from '../utils/getRandomId';
import { SharedProps } from '../utils/shared.props';
import { useControlled } from '../utils/useControlled';
import { useSummaryId } from '../utils/useSummaryId';
import { AccordionContext } from './AccordionContext';

interface Props extends Pick<ExpandProps, 'onAnimationEnd'>, SharedProps {
  children?: React.ReactNode;
  defaultExpanded?: boolean;
  onChange?: (isExpanded: boolean) => void;
  expanded?: boolean;
  lazyRenderDetails?: boolean;
  hideDivider?: boolean;
}
export const Accordion: React.FC<Props> = ({
  children,
  defaultExpanded = false,
  onChange,
  onAnimationEnd,
  lazyRenderDetails,
  extend,
  expanded,
  hideDivider,
  ...rest
}) => {
  const [isExpanded, setIsExpanded] = useControlled({
    defaultValue: defaultExpanded,
    value: expanded,
  });
  const [accordionKey] = useState(() => `_${getRandomId()}`);
  const isControlled = typeof expanded === 'boolean';
  const value = useMemo(() => {
    return {
      accordionKey,
      setIsExpanded,
      isExpanded,
      onChange,
      lazyRenderDetails,
      onAnimationEnd,
      isControlled,
    };
  }, [
    accordionKey,
    isExpanded,
    setIsExpanded,
    onChange,
    lazyRenderDetails,
    onAnimationEnd,
    isControlled,
  ]);
  return (
    <AccordionContext.Provider value={value}>
      <Wrapper {...rest} hideDivider={hideDivider} extend={extend}>
        {children}
      </Wrapper>
    </AccordionContext.Provider>
  );
};

const Wrapper: React.FC<{
  hideDivider?: boolean;
  extend: ExtendCSS;
  children: React.ReactNode;
}> = ({ children, hideDivider, extend, ...rest }) => {
  const summaryId = useSummaryId();
  return (
    <View
      {...rest}
      extend={[
        {
          extend: {
            condition: !hideDivider,
            style: {
              [`& .${summaryId}-react-accordion__divider`]: {
                display: 'block',
              },
            },
          },
        },
        extend,
      ]}
    >
      {children}
    </View>
  );
};
