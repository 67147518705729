import React from 'react';

export const PreventClickPassThrough: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => (
  /*
   * This element is used solely to capture bubbled events, so there are no appropriate rules
   * There is a top level keyboard event to capture the escape key, so no need to re-apply here
   * */
  /*
  eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
  */
  <div
    onMouseDown={(e) => e.stopPropagation()}
    onClick={(e) => e.stopPropagation()}
  >
    {children}
  </div>
);
