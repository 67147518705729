import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

interface OverlayStackerContextType {
  overlayStackedCount: React.MutableRefObject<number> | null;
}

const OverlayStackerContext = createContext<OverlayStackerContextType>({
  overlayStackedCount: null,
});

export const OverlayStacker: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const overlayStackedCount = useRef(0);

  return (
    <OverlayStackerContext.Provider
      value={{
        overlayStackedCount,
      }}
    >
      {children}
    </OverlayStackerContext.Provider>
  );
};

const getStackedZIndex = (
  zIndex: string | number,
  overlayStackedCount: React.MutableRefObject<number>
) => {
  if (typeof zIndex === 'number') {
    return zIndex + overlayStackedCount.current;
  }
  return `calc(${zIndex} + ${1 + overlayStackedCount.current})`;
};

export const useOverlayStacker = (
  zIndex: string | number,
  isOpen?: boolean
) => {
  const [stackedZIndex, setStackedZIndex] = useState(zIndex);

  const { overlayStackedCount } = useContext(OverlayStackerContext);

  useEffect(() => {
    if (!overlayStackedCount) {
      return;
    }
    if (isOpen === true) {
      setStackedZIndex(getStackedZIndex(zIndex, overlayStackedCount));
      overlayStackedCount.current++;
    }
    if (isOpen === false) {
      setStackedZIndex(zIndex);
    }
    if (typeof isOpen !== 'boolean') {
      setStackedZIndex(getStackedZIndex(zIndex, overlayStackedCount));
      overlayStackedCount.current++;
      return () => {
        setStackedZIndex(zIndex);
      };
    }
  }, [isOpen, overlayStackedCount, zIndex]);

  return {
    stackedZIndex,
  };
};
