import React from 'react';
import { Click } from 'vcc-ui';

export const InfoLink = React.forwardRef<
  HTMLAnchorElement,
  React.ComponentPropsWithoutRef<'a'>
>(function InfoLink(props, ref) {
  const { children, href } = props;

  return (
    <Click
      {...props}
      href={href}
      ref={ref}
      extend={({ theme }) => ({
        color: theme.color.foreground.primary,
        display: 'inline-block',
        textDecoration: 'underline',
        ...theme.typeScale.kelly.emphasis.styles,
      })}
    >
      {children}
    </Click>
  );
});
