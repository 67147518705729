import React from 'react';
import { Block, Icon } from 'vcc-ui';

interface InfoCTAProps {
  children?: React.ReactNode;
  mobileCollapse?: boolean;
}

export const InfoCTA = React.forwardRef<HTMLDivElement, InfoCTAProps>(
  function InfoCTAInner(props: InfoCTAProps, ref) {
    const { children, mobileCollapse = false } = props;

    return (
      <Block ref={ref} extend={{ display: 'inline-flex' }}>
        <Block
          extend={({ theme: { baselineGrid, typeScale } }) => ({
            display: 'inline-flex',
            marginLeft: baselineGrid * 3,
            ...typeScale.kelly.emphasis.styles,

            onlyS: {
              display: mobileCollapse ? 'none' : 'inline-flex',
            },
          })}
        >
          {children}
        </Block>
        <Block
          extend={({ theme: { baselineGrid } }) => ({
            display: 'inline-flex',
            marginLeft: baselineGrid,
            alignItems: 'center',
          })}
        >
          <Icon type="navigation-chevronforward-12" />
        </Block>
      </Block>
    );
  }
);
