import { ExtendCSS } from 'vcc-ui';

export const CLOSE_BAR_HEIGHT = 64;

export const closeBarStyle: ExtendCSS = ({
  theme: { color, baselineGrid },
}) => ({
  backgroundColor: color.background.primary,
  boxSizing: 'border-box',
  width: '100%',
  position: 'sticky',
  top: 0,
  paddingLeft: 3 * baselineGrid,
  paddingRight: 3 * baselineGrid,
  alignItems: 'flex-end',
  justifyContent: 'center',
  height: `${CLOSE_BAR_HEIGHT}px`,
});
