import React from 'react';
import { useTranslate } from '@volvo-cars/react-shared-translations';
import { Click, Icon, Text, ThemePicker } from 'vcc-ui';

export interface InfoBannerProps {
  children: React.ReactNode;
  offsetY?: number | string;
  onDismiss?: (e: React.MouseEvent<HTMLElement>) => void;
  sticky?: boolean;
  zIndex?: number | string;
}

export const InfoBanner = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<InfoBannerProps>
>(function InfoBannerInner(props: InfoBannerProps, ref) {
  const {
    children,
    offsetY = 0,
    onDismiss,
    sticky = false,
    zIndex = 10,
  } = props;

  const closeLabel = useTranslate('react-info-banner.close');

  return (
    <ThemePicker variant="dark">
      <Text
        as="div"
        ref={ref}
        variant="kelly"
        subStyle="emphasis"
        extend={({ theme: { baselineGrid, color } }) => ({
          backgroundColor: color.background.primary,
          boxSizing: 'border-box',
          display: 'flex',
          padding: `${baselineGrid * 1.5}px ${baselineGrid * 3}px`,
          position: sticky ? 'sticky' : 'relative',
          top: offsetY,
          whiteSpace: 'pre',
          zIndex: zIndex as any,

          justifyContent: 'flex-start',
          fromM: {
            justifyContent: 'center',
          },
        })}
      >
        {children}

        {onDismiss && (
          <Click
            extend={({ theme }) => ({
              display: 'inline-flex',
              position: 'absolute',
              right: `${theme.baselineGrid * 3}px`,
            })}
            onClick={(e: React.MouseEvent<HTMLElement>) => onDismiss(e)}
            aria-label={closeLabel}
          >
            <Icon type="navigation-close-24" />
          </Click>
        )}
      </Text>
    </ThemePicker>
  );
});
