import React, { forwardRef, useCallback, useEffect, useRef } from 'react';
import { usePrevious } from '@volvo-cars/react-utils';
import { Icon, Inline } from 'vcc-ui';
import { useAccordion } from '../Accordion/AccordionContext';
import { useAccordionController } from '../AccordionController';
import { useKeyboardNavigation } from '../AccordionController/AccordionController';
import { ButtonDiv } from '../utils/ButtonDiv';
import { SharedProps } from '../utils/shared.props';
import { transitionTimingFunction } from '../utils/transition';
import { useSummaryId } from '../utils/useSummaryId';

export interface AccordionSummaryBaseProps extends SharedProps {
  onInteraction?: (data: {
    event: React.KeyboardEvent<HTMLElement> | React.MouseEvent<HTMLElement>;
    expanded?: boolean;
  }) => void;
  role?: string;
  iconAlignment?: 'top' | 'center';
}
interface ChildrenFunction {
  children: (
    props: AccordionSummaryBaseProps & {
      isExpanded?: boolean;
      ref: React.ForwardedRef<HTMLDivElement>;
    }
  ) => JSX.Element;
}

export const AccordionSummaryBase = forwardRef<
  HTMLDivElement,
  React.ComponentPropsWithoutRef<
    React.FC<AccordionSummaryBaseProps & ChildrenFunction>
  >
>(function AccordionSummary(
  { children, onInteraction, iconAlignment = 'top', extend, ...rest },
  ref
) {
  const { updateOpenAccordions, openAccordions } =
    useAccordionController() || {};

  const {
    accordionKey,
    setIsExpanded,
    isExpanded,
    onChange: onAccordionChange,
    isControlled,
  } = useAccordion() || {};
  const openAccordionsSize = openAccordions?.size;
  const previousExpandedState = usePrevious(!!isExpanded);
  const isInitialRender = useIsInitialRender();

  useEffect(() => {
    if (isControlled || isInitialRender) return;
    if (isExpanded !== previousExpandedState) {
      onAccordionChange?.(!!isExpanded);
    }
  }, [
    onAccordionChange,
    isExpanded,
    isControlled,
    previousExpandedState,
    isInitialRender,
  ]);

  const handleExpand = useCallback(
    (
      event: React.KeyboardEvent<HTMLElement> | React.MouseEvent<HTMLElement>
    ) => {
      onInteraction?.({ event, expanded: !isExpanded });
      if (isControlled) {
        onAccordionChange?.(!isExpanded);
      }
      // If no AccordionController is set, handle accordion as a singleton
      if (!updateOpenAccordions || !accordionKey) {
        setIsExpanded?.((isExpanded) => !isExpanded);
        return;
      }
      updateOpenAccordions(accordionKey, !isExpanded);
      setIsExpanded?.(!isExpanded);
    },
    [
      updateOpenAccordions,
      accordionKey,
      setIsExpanded,
      isExpanded,
      onInteraction,
      isControlled,
      onAccordionChange,
    ]
  );

  const isActive = !!openAccordions?.has(accordionKey || '');

  useEffect(() => {
    if (!setIsExpanded || !openAccordionsSize) return;
    setIsExpanded(isActive);
  }, [isActive, setIsExpanded, openAccordionsSize]);

  const summaryId = useSummaryId();
  const id = summaryId ? `${summaryId}-header` : undefined;
  const controls = summaryId ? `${summaryId}-content` : undefined;

  const keyboardNavigationProps = useKeyboardNavigation({
    ref,
    orientation: 'vertical',
  });

  return (
    <ButtonDiv
      {...rest}
      {...keyboardNavigationProps}
      extend={({ theme }) => ({
        cursor: 'pointer',
        border: '1px solid transparent',
        ':focus-visible': theme.states?.focus,
      })}
      aria-controls={controls}
      id={id}
      onClick={handleExpand}
      aria-expanded={isExpanded}
    >
      {typeof children === 'function'
        ? children({
            onInteraction,
            iconAlignment,
            isExpanded,
            extend,
            ref,
            ...rest,
          })
        : children}
    </ButtonDiv>
  );
});

export const Chevron: React.FC<{
  direction: 'up' | 'down';
  size?: '12' | '16' | '24' | '32' | '40';
}> = ({ direction, size = '16' }) => {
  const rotate = direction === 'up' ? 180 : 0;

  return (
    <Inline
      extend={{
        speak: 'none',
        display: 'flex',
        height: 'inherit',
        width: 'inherit',
        transform: `rotate(${rotate || 0}deg)`,
        transformOrigin: 'center',
        transition: `transform 300ms ${transitionTimingFunction}`,
      }}
    >
      <Icon type={`navigation-chevrondown-${size}`} />
    </Inline>
  );
};

const useIsInitialRender = () => {
  const isMountRef = useRef(true);
  useEffect(() => {
    isMountRef.current = false;
  }, []);
  return isMountRef.current;
};
