import React, { createContext, useContext } from 'react';
import { ExpandProps } from '../utils/Expand';

interface ContextValue extends Pick<ExpandProps, 'onAnimationEnd'> {
  accordionKey: string;
  setIsExpanded: React.Dispatch<React.SetStateAction<boolean>>;
  isExpanded: boolean;
  disabled?: boolean;
  onChange?: (expanded: boolean) => void;
  lazyRenderDetails?: boolean;
  isControlled: boolean;
}
export const AccordionContext: React.Context<ContextValue | undefined> =
  createContext<ContextValue | undefined>(undefined);

export const useAccordion = (): ContextValue | undefined =>
  useContext(AccordionContext);
