import React, { forwardRef } from 'react';
import { View } from 'vcc-ui';
import { SharedProps } from '../utils/shared.props';

export const AccordionAction = forwardRef<
  HTMLDivElement,
  React.ComponentPropsWithoutRef<
    React.FC<SharedProps & { children?: React.ReactNode }>
  >
>(function AccordionAction({ children, ...rest }, ref) {
  return (
    <View
      {...rest}
      ref={ref}
      as="div"
      onClick={(event: React.SyntheticEvent<HTMLElement>) =>
        event.stopPropagation()
      }
      onKeyPress={(event: React.SyntheticEvent<HTMLElement>) =>
        event.stopPropagation()
      }
    >
      {children}
    </View>
  );
});
