import { useCallback, useState } from 'react';

export const useControlled = ({
  value: valueProp,
  defaultValue,
}: {
  value: boolean | undefined;
  defaultValue: boolean | undefined;
}): readonly [boolean, React.Dispatch<React.SetStateAction<boolean>>] => {
  const isControlled = typeof valueProp === 'boolean';
  const [valueState, setValue] = useState(defaultValue || false);
  const value = isControlled ? valueProp : valueState;
  const dummySetValue = useCallback(() => {}, []) as React.Dispatch<
    React.SetStateAction<boolean>
  >;
  return [value, isControlled ? dummySetValue : setValue] as const;
};
