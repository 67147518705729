import React, { memo, useCallback, useRef, useState } from 'react';
import { useLayoutEffect } from '@volvo-cars/react-layout-utils';
import { Block, ExtendCSS } from 'vcc-ui';
import { SharedProps } from './shared.props';
import { transitionTimingFunction } from './transition';

export interface ExpandProps extends SharedProps {
  children?: React.ReactNode;
  isExpanded: boolean;
  animateOpacity?: boolean;
  unmountOnExit?: boolean;
  duration?: number;
  onAnimationEnd?: (isVisible?: boolean) => void;
  reducedMotion?: boolean;
}
export const Expand: React.FC<ExpandProps> = memo(function InnerExpand({
  isExpanded,
  animateOpacity = false,
  unmountOnExit = false,
  duration = 300,
  onAnimationEnd,
  children,
  reducedMotion,
  ...rest
}) {
  const [height, setHeight] = useState(isExpanded ? 'auto' : '0px');
  const [isVisible, setIsVisible] = useState(isExpanded);
  const [animateExpand, setAnimateExpand] = useState(isExpanded);
  const [shouldMount, setShouldMount] = useState(!unmountOnExit);
  const ref = useRef<HTMLDivElement>(null);
  const entryTimer = useRef<number>();
  const leaveTimer = useRef<number>();
  const resetTimer = useRef<number>();
  duration = reducedMotion ? 0 : duration;

  const updateHeight = useCallback(() => {
    setHeight(ref.current ? ref.current.offsetHeight + 'px' : '0px');
  }, []);

  // mount initial, used for unmountOnExit
  useLayoutEffect(() => {
    if (isExpanded) {
      setShouldMount(true);
    }
  }, [isExpanded]);

  useLayoutEffect(() => {
    if (!shouldMount) return;
    updateHeight();
    if (isExpanded) {
      setIsVisible(true);
    }
    // Start expand/collapse animation
    entryTimer.current = window.requestAnimationFrame(() => {
      setAnimateExpand(isExpanded);
    });

    // Reset height after animation, set auto to allow for dynamic content inside content after opening
    if (isExpanded) {
      resetTimer.current = window.setTimeout(
        () => {
          setHeight('auto');
          // Add fake 50ms since transitionEnd is ignored when `auto` height is set
        },
        duration ? duration + 50 : 0
      );
    } else {
      leaveTimer.current = window.setTimeout(() => {
        setIsVisible(isExpanded);
        // unmount on animation finish
        if (unmountOnExit) {
          setShouldMount(isExpanded);
        }
        // hide midway for faster close animations
      }, duration / 1.5);
    }

    return () => {
      if (typeof entryTimer.current === 'number') {
        cancelAnimationFrame(entryTimer.current);
      }
      clearTimeout(leaveTimer.current);
      clearTimeout(resetTimer.current);
    };
  }, [isExpanded, updateHeight, shouldMount, unmountOnExit, duration]);
  return (
    <Block
      onTransitionEnd={(event: React.TransitionEvent) => {
        event.stopPropagation();
        onAnimationEnd?.(isVisible);
      }}
      extend={containerCSS({
        isVisible,
        animateExpand,
        height,
        animateOpacity,
        duration,
        reducedMotion,
      })}
    >
      {shouldMount ? (
        <Block ref={ref} {...rest}>
          {children}
        </Block>
      ) : null}
    </Block>
  );
});

const containerCSS = ({
  isVisible,
  animateExpand,
  height,
  animateOpacity,
  duration,
  reducedMotion,
}: {
  isVisible: boolean;
  animateExpand: boolean;
  height: string;
  animateOpacity: boolean;
  duration: number;
  reducedMotion?: boolean;
}): ExtendCSS => ({
  padding: 0,
  margin: 0,
  height: 0,
  overflow: 'hidden',
  visibility: isVisible ? 'visible' : 'hidden',
  transition: reducedMotion
    ? ''
    : `height ${duration}ms ${transitionTimingFunction}`,
  extend: [
    {
      condition: animateExpand,
      style: {
        visibility: 'visible',
        height,
      },
    },
    {
      condition: animateOpacity,
      style: {
        transition: reducedMotion
          ? ''
          : `height ${duration}ms ${transitionTimingFunction},opacity ${duration}ms ${transitionTimingFunction}`,
        opacity: animateExpand ? 1 : 0,
      },
    },
  ],
});
