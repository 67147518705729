import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { createKeyboardNavigation } from '@volvo-cars/react-aria';
import { useMediaQuery } from '../utils/useMediaQuery';

export const { useKeyboardNavigation, KeyboardNavigationProvider } =
  createKeyboardNavigation();

interface AccordionControllerContextValue {
  openAccordions: Set<string>;
  updateOpenAccordions: (accordionKey: string, add?: boolean) => void;
  reducedMotion?: boolean;
}
const AccordionControllerContext = createContext<
  AccordionControllerContextValue | undefined
>(undefined);
export const AccordionController: React.FC<{
  multiple?: boolean;
  children?: React.ReactNode;
}> = ({ children, multiple = false }) => {
  const [openAccordions, setOpenAccordions] = useState<Set<string>>(
    () => new Set()
  );
  const reducedMotion = useMediaQuery('(prefers-reduced-motion)');
  const updateOpenAccordions = useCallback(
    (accordionKey: string, add = true) => {
      if (!add) {
        setOpenAccordions((openAccordions) => {
          openAccordions.delete(accordionKey);
          return new Set(openAccordions);
        });
        return;
      }
      if (multiple) {
        setOpenAccordions(
          (openAccordions) => new Set(openAccordions.add(accordionKey))
        );
      } else {
        setOpenAccordions(new Set([accordionKey]));
      }
    },
    [multiple]
  );
  const value = useMemo(() => {
    return {
      openAccordions,
      updateOpenAccordions,
      reducedMotion,
    };
  }, [openAccordions, updateOpenAccordions, reducedMotion]);
  return (
    <AccordionControllerContext.Provider value={value}>
      <KeyboardNavigationProvider>{children}</KeyboardNavigationProvider>
    </AccordionControllerContext.Provider>
  );
};
export const useAccordionController = ():
  | AccordionControllerContextValue
  | undefined => useContext(AccordionControllerContext);
