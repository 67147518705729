import { useState } from 'react';
import { useLayoutEffect } from '@volvo-cars/react-layout-utils';
import { ONETRUST_CONTAINER_ID } from './constants';

export const useContainerElements = (
  childRef: React.MutableRefObject<HTMLDivElement | null>
) => {
  const [containerElements, setContainerElements] = useState<HTMLElement[]>([]);

  useLayoutEffect(() => {
    const oneTrustContainer = document.getElementById(ONETRUST_CONTAINER_ID);

    setContainerElements(
      [childRef.current, oneTrustContainer].filter(Boolean) as HTMLElement[]
    );

    if (oneTrustContainer) return;

    const observer = new MutationObserver((mutationList, observer) => {
      for (const mutation of mutationList) {
        if (mutation.type === 'childList') {
          for (const addedNode of mutation.addedNodes) {
            if (isHTMLElement(addedNode)) {
              if (addedNode.id === ONETRUST_CONTAINER_ID) {
                observer.disconnect();

                setContainerElements(
                  [childRef.current, addedNode].filter(Boolean) as HTMLElement[]
                );

                break;
              }
            }
          }
        }
      }
    });

    observer.observe(document.body, { childList: true });

    return () => observer.disconnect();
  }, [childRef]);

  return containerElements;
};

function isHTMLElement(node: Node): node is HTMLElement {
  return node.nodeType === 1;
}
