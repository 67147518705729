import React, { createContext, useContext } from 'react';
import { Transition, TransitionStatus } from 'react-transition-group';
import { ExtendCSS, Flex } from 'vcc-ui';
import useResetScroll from '../hooks/useResetScroll';
import { useOverlayStacker } from '../OverlayStacker';

export interface AppearProps {
  children: React.ReactNode;
  isOpen?: boolean;
  resetScroll?: boolean;
  zIndex?: number | string;
}

const TRANSITION_TIME = 300;

const AppearContext = createContext({
  isOpen: true,
  resetScroll: true,
});

export const Appear: React.FC<AppearProps> = ({
  children,
  isOpen = false,
  resetScroll = true,
  zIndex = 'calc(var(--sitenav-z-index-max, 1010) + 1)',
}: AppearProps) => {
  const { stackedZIndex } = useOverlayStacker(zIndex, isOpen);

  return (
    <Transition in={isOpen} timeout={TRANSITION_TIME} appear>
      {(state: TransitionStatus) => {
        return (
          <Flex
            aria-hidden={!isOpen}
            extend={containerStyle({
              isOpen,
              state,
              transitionTime: TRANSITION_TIME,
              zIndex: stackedZIndex,
            })}
          >
            <AppearContext.Provider
              value={{
                isOpen: state === 'entered',
                resetScroll,
              }}
            >
              {children}
            </AppearContext.Provider>
          </Flex>
        );
      }}
    </Transition>
  );
};

const containerStyle =
  ({
    isOpen,
    state,
    transitionTime,
    zIndex,
  }: {
    isOpen: boolean;
    state: TransitionStatus;
    transitionTime: number;
    zIndex: string | number;
  }): ExtendCSS =>
  () => ({
    overflowY: 'hidden',
    opacity: state === 'exiting' || state === 'exited' ? 0 : 1,
    visibility: state === 'exited' && !isOpen ? 'hidden' : 'visible',
    transition: `opacity ${transitionTime}ms ease-out`,
    position: 'fixed',
    top: state === 'exiting' || state === 'exited' ? 'calc(200vh + 100%)' : 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex,
  });

export const useAppear = ({
  scrollingElRef,
}: {
  scrollingElRef: React.RefObject<HTMLDivElement>;
}) => {
  const { isOpen, resetScroll } = useContext(AppearContext);

  useResetScroll({
    isOpen,
    resetScroll,
    scrollingElRef,
  });

  return { isOpen };
};
