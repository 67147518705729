import React, { forwardRef } from 'react';
import { useTranslate } from '@volvo-cars/react-shared-translations';
import { Flex, IconButton, Text } from 'vcc-ui';
import { contentWrap } from '../shared/style';
import {
  iconAside,
  title,
  titleBarStyle,
  titleTextTransition,
} from './TitleBar.styles';

export interface TitleBarProps {
  /** The function to be invoked when the left icon is clicked */
  backArrowAction?: () => void;

  /** String to be used as the title */
  children: string;

  /** The function to be invoked when closing the modal */
  closeAction: () => void;

  /** Whether TitleBar is position fixed or static. Default is static.
   * @default false
   */
  fixed?: boolean;

  /** Unique identifier used to reconcile aria label */
  id: string;

  /**
   * Used to determine the display height of the component
   * @deprecated This prop will be ignored
   */
  minimised?: boolean;

  /** Used to programmatically toggle the back arrow display
   * @default false
   */
  showBackArrow?: boolean;
}

export const TitleBar = forwardRef<HTMLDivElement, TitleBarProps>(
  (
    {
      children,
      closeAction,
      fixed = false,
      id,
      showBackArrow = false,
      backArrowAction,
    }: TitleBarProps,
    ref
  ) => {
    const closeLabel = useTranslate('react-overlay.close');
    const backLabel = useTranslate('react-overlay.back');

    return (
      <Flex ref={ref} id={id} extend={titleBarStyle(fixed)}>
        <Flex extend={contentWrap}>
          <Flex extend={iconAside}>
            {showBackArrow && backArrowAction && (
              <IconButton
                onClick={backArrowAction}
                aria-label={backLabel}
                iconName="navigation-chevronback"
              />
            )}
          </Flex>

          <Flex extend={title}>
            <Text as="h2" subStyle="emphasis" extend={titleTextTransition}>
              {children}
            </Text>
          </Flex>

          <Flex extend={iconAside}>
            <IconButton
              iconName="navigation-close"
              aria-label={closeLabel}
              onClick={closeAction}
            />
          </Flex>
        </Flex>
      </Flex>
    );
  }
);

TitleBar.displayName = 'TitleBar';
