import { useEffect, useState } from 'react';
import { useScrollLock } from '@volvo-cars/react-scroll-utils';
import { useKeyPress } from '@volvo-cars/react-utils';
import { useTracker } from '@volvo-cars/tracking';

const useOverlay = ({
  close,
  isOpen,
}: {
  close: () => void;
  isOpen: boolean;
}) => {
  const [elementsMounted, setElementsMounted] = useState(isOpen);
  const tracker = useTracker(
    null,
    { ga3: { eventAction: 'close' } },
    { ignoreIfEmptyContext: true }
  );

  const closeAndTrackEvent = () => {
    tracker.interaction({
      eventAction: 'overlay|close',
    });
    close();
  };

  useEffect(() => {
    setElementsMounted(isOpen);
  }, [isOpen]);

  useKeyPress('Escape', closeAndTrackEvent);

  useScrollLock(elementsMounted);

  return {
    closeAndTrackEvent,
    elementsMounted,
  };
};

export default useOverlay;
